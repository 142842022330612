@import "/src/styles/mixin";


.main {
  padding-bottom: 0;
  align-items: center;

  .head {
    padding: 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .head_container {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      max-width: 800px;
      min-height: 300px;
      // background-color: var(--primary-color);
      border-radius: var(--border-radius-medium);
      gap: 24px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, .15);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--secondary-color);
        // transform: translate(-40%, 0) rotate(-45deg);
        z-index: 1;
        background-image: repeating-linear-gradient(-45deg,
            #363636,
            #363636 10px,
            transparent 10px,
            transparent 20px);
        // opacity: .2;
      }

      .title {
        z-index: 2;
        margin: 0 0 0 24px;
        color: var(--white-color);

        @media screen and (max-width: 767px) {
          margin: 0;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 0 24px 24px 0;
        padding: 0 0;
        padding: 0 24px 0 24px;
        color: var(--white-color);
        z-index: 2;

        form {
          margin-bottom: 0;
        }

        p {
          color: var(--white-color);
        }

        @media only screen and (min-width: 320px) and (max-width: 767.98px) {
          padding-bottom: 24px;
        }
      }

      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        flex-direction: column;
        padding: 24px 0 0 0;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding: 60px 0;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      padding: 40px 0;
    }
  }
}

.title {
  @include font24;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;

  &Link {
    @include font14;
    font-weight: 400;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--grey-color);

    &::before {
      content: "";
      width: 0;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &::after {
      content: "";
      width: 32px;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &:hover {
      &::before {
        width: 16px;
      }

      &::after {
        width: 16px;
      }
    }
  }
}

.nestedBanner {
  height: auto;
}