@import "./src/styles/mixin";

.swiper-sales {
  .swiper {
    &-wrapper {
      padding-bottom: 30px;
    }
    &-button {
      @include arrowSwiper;
    }
    &-pagination {
      bottom: 0 !important;
      &-bullet-active {
        background: var(--grey-color);
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }
}